.header-row {
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;
  }
  
  .header-title {
    flex-grow: 1;
    text-align: center;
  }
  
  .header-title h4 {
    margin: 0;
    font-size: 1.25rem;
  }
  
  .project-id {
    text-align: right;
    font-size: 0.875rem;
    color: #6c757d;
  }
  
  .back-link {
    color: #007bff;
    display: flex;
    align-items: center;
  }
  
  #project-edit-tabs .nav-link {
    color: #007bff;
    font-weight: 500;
  }
  
  #project-edit-tabs .nav-link.active {
    background-color: #007bff;
    color: white;
  }
  