/* App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sideNav {
    position: fixed;
    top: 56px; /* height of the navbar */
    bottom: 0;
    width: 200px;
    background-color: #343a40;
    padding-top: 20px;
    z-index: 1000;
    box-shadow: 4px 0 6px rgba(0, 0, 0, 0.1);
  }
  
  .content {
    margin-left: 200px; /* width of the sidenav */
    margin-top: 56px; /* height of the navbar */
    padding: 20px;
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
  
  .sideNav .nav-link {
    color: #ffffff;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .sideNav .nav-link:hover {
    background-color: #495057;
    color: #ffffff;
  }
  
  .sideNav .nav-link.active {
    background-color: #007bff;
    color: #ffffff;
  }
  
  button {
    margin-left: auto;
  }
  