.create-project-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .date-picker {
    width: 100%;
  }
  
  .save-button {
    width: 100%;
  }
  