.search-row {
    margin-bottom: 20px;
  }
  
  .search-group {
    width: 100%;
  }
  
  .search-icon {
    background-color: #e9ecef;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .projects-table {
    width: 100%;
  }
  
  .projects-table th,
  .projects-table td {
    vertical-align: middle;
    text-align: center;
  }
  
  .name-column {
    width: 20%; /* Set width for the name column */
  }
  
  .duration-column {
    width: 25%; /* Set a more appropriate width for the duration column */
  }
  
  .notes-column {
    width: 55%; /* Increase the width for notes */
  }
  
  .project-link {
    text-decoration: none;
    color: #007bff;
  }
  
  .project-link:hover {
    text-decoration: underline;
  }
  